import React, { useEffect, useState } from "react"; // Import React and hooks once
import { useNavigate } from "react-router-dom";
import topFarms from "../assets/images/Quests/topfarms.png";
import BackToTopGlobal from '../components/BackToTop2';

const LandSpeck = () => {
  const [selectedCategory, setSelectedCategory] = useState("Lands");
  const navigate = useNavigate();
  const [discoverabilityItems, setDiscoverabilityItems] = useState([]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`#${category.toLowerCase()}`);
  };

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      setSelectedCategory(hash.charAt(0).toUpperCase() + hash.slice(1));
    }
  }, []);

  useEffect(() => {
    if (selectedCategory === "Discoverability") {
      fetchDiscoverabilityItems();
    }
  }, [selectedCategory]);

  const fetchDiscoverabilityItems = async () => {
    try {
      const response = await fetch("https://api.pixelore.wiki/api/discitems");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setDiscoverabilityItems(data);
    } catch (error) {
      console.error("Failed to fetch discoverability items", error);
    }
  };

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-5xl font-heading mb-3 text-center text-retroegg">Land & Specks</h1>
      <div className="flex justify-center items-start min-h-fit">
        {["Lands", "Specks", "Discoverability"].map((category) => (
          <button
            key={category}
            className={`py-2 px-4 m-2 rounded-full ${selectedCategory === category ? "bg-retropurple text-white" : "bg-retrosilver text-gray-700"}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="guide-content space-y-4">
        {selectedCategory === "Lands" && (
          <section id="lands" className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">About Pixels Lands</h2>
            <p className="starterText">
              There are 5000 NFT Farm Lands made specifically for the game Pixels. They were minted on January 31 2022 for 0.06 ETH to players of their Play-to-Mint. Top 2000
              players of the Play-to-Mint could purchase 2 lands, and everyone else could mint 1. 200 Lands were kept in reserve.
              <br />
              <br />
              Top 101 Players of the Play-to-Mint also received a Golden Rake.
              <br />
              <br />
              Land NFTs have 7 traits: Size, Environment, House, Tree Density, Windmill, Coop, and Silo. Every land comes with a Quonset Hut which is essential for storing the
              land's surplus. Surplus is a percentage of resources used on a farm land. (Not a tax, but it's like extra generation from a player utilizing an industry on your
              land). Since Pixels is a growing game that proudly boasts building in public, trait values change in importance as things change within the game.
              <br />
              <br />
              Land owners can also place industries, decor, and other items on their land to make it more attractive or more useful.
              <br />
              Lands are not necessary to play the game, but it opens up a new avenue for players. Being a land owner also has its own perks at times.
            </p></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Traits</h2>
            <div className="starterText overflow-x-auto">
              <table className="table1 text-wrap">
                <thead>
                  <tr>
                    <th>Trait</th>
                    <th>Options</th>
                    <th>Amount</th>
                    <th>Benefits</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan="2">Size</td>
                    <td>Small</td>
                    <td>19% - 950</td>
                    <td rowSpan="2">Determines the amount of space on a land</td>
                  </tr>
                  <tr>
                    <td>Large</td>
                    <td>81% - 4050</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">House</td>
                    <td>Small</td>
                    <td>61% - 3050</td>
                    <td> 4 Floors</td>
                  </tr>
                  <tr>
                    <td>Large</td>
                    <td>39% - 1950</td>
                    <td> 5 Floors and a solarium</td>
                  </tr>
                  <tr>
                    <td rowSpan="3">Environment</td>
                    <td>Land</td>
                    <td>50.5% - 2524</td>
                    <td>Mines: Clay</td>
                  </tr>
                  <tr>
                    <td>Water</td>
                    <td>38.6% - 1931</td>
                    <td>Mines: Salt. Farming: Watermints [seeds cannot be purchased]</td>
                  </tr>
                  <tr>
                    <td>Space</td>
                    <td>10.9% - 545</td>
                    <td>Mines: Voidtonium. Farming: Astracactus [seeds cannot be purchased]</td>
                  </tr>
                  <tr>
                    <td rowSpan="3">Tree Density</td>
                    <td>Light</td>
                    <td>70% - 3500</td>
                    <td>Trees have slightly higher drop rate.</td>
                  </tr>
                  <tr>
                    <td>Dense</td>
                    <td>16% - 800</td>
                    <td>Trees have a higher drop rate</td>
                  </tr>
                  <tr>
                    <td>None</td>
                    <td>14% - 700</td>
                    <td>Trees have similar drop rates as Specks</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">Coop</td>
                    <td>Yes</td>
                    <td>49% - 2450</td>
                    <td rowSpan="2">Currently, these are the only lands with a Coop. There is speculation of tiered coops but no confirmation.</td>
                  </tr>
                  <tr>
                    <td>No</td>
                    <td>51% - 2550</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">Windmill</td>
                    <td>Yes</td>
                    <td>51% - 2550</td>
                    <td rowSpan="2">Currently, these are the only lands with a Windmill. There is speculation of tiered windmills but no confirmation.</td>
                  </tr>
                  <tr>
                    <td>No</td>
                    <td>49% - 2450</td>
                  </tr>
                  <tr>
                    <td rowSpan="2">Silo</td>
                    <td>Yes</td>
                    <td>50% - 2500</td>
                    <td rowSpan="2">Silo provides more space for Surplus [36 instead of 24]</td>
                  </tr>
                  <tr>
                    <td>No</td>
                    <td>50% - 2500</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p>
              On top of traits, Lands go up to Tier 4. So you can place industries from Tier 1 to Tier 4 on the land. The only limitation on lands is how much space is available.<br/>
              Surplus is set at 4-6% (4% on the lower end of the level and 6% on the higher end)<br/>
              So if you have level 100 Mining, you get 6% surplus from Mining on land. (same for farming or forestry)<br/>
              If you have level 0 Mining, you get 4% Surplus from Mining on land. (same for farming or forestry)
            </p>
            </div></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Land Owner Perks</h2>
            <div className="starterText">
              <ul className="basicLists">
                <li>1 Extra Bookmark (After 7 days holding, 1 Additional Bookmark)</li>
                <li>6 Extra Bag Space (After 7 days holding, 6 extra bag space)</li>
                <li>Land Owner Tasks on Task Board (After 7 days holding, VIP tasks on Task Board)</li>
                <li>1,500 Reputation</li>
                <li>5 Additional MP listing slots (After 7 days holding, lower fees, additional MP slots)</li>
                <li>Easy access to lands in Lands Tab</li>
                <li>Quantum Recombinator Access</li>
                <li>Land Surplus from Resource Industries (industries that provide raw materials, not crafting industries)</li>
              </ul>
            </div></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Settings</h2>
            <p className="starterText">
              Every NFT Land has its own access settings. A land owner can add a builder or manager to its land, make the land private, or give a guild access to the land.
              <br />
              You can do all of these things at the Gate which is located at the entrance of the land.
              <br />
              <br />
              Land Access Settings: A <b>Manager</b> has rights to do everything on a land, except access storage chests. They can remove/add industries or decorations, access
              surplus or change settings of the land.
              <br />A <b>Builder</b> can add items to a land, but cannot remove them (though if they placed the item, they can still remove it). They cannot access surplus or
              manage settings.
              <br />
              Giving access to a <b>guild</b> makes the land locked to everyone except players in that guild (You can further limit this by setting it to either Pledgers,
              Supporters, etc.)
            </p></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Ownership of Items on Lands</h2>
            <p className="starterText">
              Whoever places an industry on a land (or a decor) is the person that the item will go back to when it is removed.
              <br />
              Common Complains are seen that this doesn't apply to the Pixel Board or the Christmas Tree. Remove your stuff before you lose access to a land you don't own!
              <br />
              Also stuff in the house is kinda hard to get back if you don't have access to the land (˶˃⤙˂˶)
            </p></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Ronin vs Ethereum</h2>
            <p className="starterText">
              Lands on Ronin can be purchased on the Ronin Marketplace, and lands on ETH can be purchased on the Ethereum Chain via Blur, OpenSea or any other marketplace available
              <br />
              There is no difference between the lands on ETH vs Ronin except the chain where they're located
            </p></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Land Rental</h2>
            <p className="starterText">
              You can rent your land out if you're not using it, or you can rent a land for yourself and you'll be deemed a land owner. If you do list your only land, you will lose
              land owner benefits while the land is listed.
              <br />
              Land Rentals are most popularly done on{" "}
              <a href="https://www.lootrush.com" target="_blank" rel="noreferrer">
                LootRush
              </a>
              , but{" "}
              <a href="https://market.renft.io/collections/pixels-farm" targer="_blank" rel="noreferrer">
                reNFT
              </a>{" "}
              is also an option [though not as widely used]
              <br />
              <br />
              After you've rented a land, you should see it in game immediately if the right wallet is connected. If you've rented out your land, you should immediately lose access
              to it in game, and another player will then have access. The other player will not get builder or manager status.
            </p></div>
          </section>
        )}

        {selectedCategory === "Specks" && (
          <section id="specks" className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Specks in Pixels</h2>
            <p className="starterText">
              Speck Lands are small free lands that every player starts with when they open an account. After the tutorial, Barney sends the player to their Speck Land and in the
              early parts of the game, this might be the most common location for a player!
              <br />
              Each Speck starts with a small house, 4 Trees, 6 Soils, and a Tier 1 Mine.
              <br />
              Speck NFTs are tiny lands that are accessible to everyone - they are smaller, don't have land traits and they don't generate surplus.
              <br />
              And while friends can visit your Speck, they can't use any of the industries on them
            </p></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Tiers</h2>
            <div className="starterText">
              <p>
              A speck can have up to Tier 2 Industries. For higher than Tier 2, a player must use an NFT Land.
              <br />
              These tiers come at different costs per house and Speck. Every house and speck has upgrades, and these upgrades can have either Tier 1 or Tier 2.
              <br /></p>
              <div className="overflow-x-auto">
              <table className="tablelb text-retrocream">
                <thead>
                  <tr>
                    <th>Upgrade</th>
                    <th>Type</th>
                    <th>Cost</th>
                    <th>Size</th>
                    <th>Tier</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Micro</td>
                  <td>Land</td>
                  <td>Free</td>
                  <td>104 Tiles</td>
                  <td>Tier 1</td>
                </tr>
                <tr>
                  <td>Mini</td>
                  <td>Land</td>
                  <td>500 Coins</td>
                  <td>170 Tiles</td>
                  <td>Tier 1</td>
                </tr>
                <tr>
                  <td>Teeny</td>
                  <td>Land</td>
                  <td>5,000 Coins</td>
                  <td>252 Tiles</td>
                  <td>Tier 1</td>
                </tr>
                <tr>
                  <td>Tiny</td>
                  <td>Land</td>
                  <td>50,000 Coins</td>
                  <td>350 Tiles</td>
                  <td>Tier 1</td>
                </tr>
                <tr>
                  <td>Petite</td>
                  <td>Land</td>
                  <td>10 $PIXEL</td>
                  <td>464 Tiles</td>
                  <td>Tier 1 & 2</td>
                </tr>
                <tr>
                  <td>Pocket</td>
                  <td>Land</td>
                  <td>20 $PIXEL</td>
                  <td>594 Tiles</td>
                  <td>Tier 1 & 2</td>
                </tr>
                <tr>
                  <td>Small Interior</td>
                  <td>House</td>
                  <td>Free</td>
                  <td>1 Floor</td>
                  <td>Tier 1</td>
                </tr>
                <tr>
                  <td>Medium Interior</td>
                  <td>House</td>
                  <td>50,000 Coins</td>
                  <td>2 Floors</td>
                  <td>Tier 1</td>
                </tr>
                <tr>
                  <td>Large Interior</td>
                  <td>House</td>
                  <td>10 $PIXEL</td>
                  <td>3 Floors</td>
                  <td>Tier 1 & 2</td>
                </tr>
                </tbody>
              </table></div>
            </div></div>
          </section>
        )}

        {selectedCategory === "Discoverability" && (
          <section id="discoverability" className="mb-6">
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Discoverability of Lands and Specks</h2>
            <div className="starterText"><p>
              Some items in game have a special little attachment on them called discoverability points. They're not on everything, but they're on some items, and you can place
              them on your land to increase the Discoverability.
              <br /></p>
              <hr className="prettyDivide" />
              <br /><p>
              They don't really serve much purpose but they do get your land higher up on the{" "}
              <span className="top-farms-board-container">
                <span>Top Farms Board</span>
                <img src={topFarms} alt="Top Farms Board" className="top-farms-board-image" />
              </span>{" "}
              ✧｡٩(ˊᗜˋ)و✧*｡ sometimes players use the board to find lands or just to visit</p>
            </div></div>
            <div className="bg-retropb rounded-md p-2 mt-2">
            <h2 className="starterTitle">Which items have discoverability Points?</h2>
            <div className="starterText"><p>
              These are the items that I know of that have points in the game:</p>
              <div className="overflow-x-auto">
              <table className="tablelb text-retrocream w-full">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Points</th>
                    <th>How To Get</th>
                    <th>How Many Exist</th>
                    <th>Other</th>
                  </tr>
                </thead>
                <tbody>
                  {discoverabilityItems.map((item) => (
                    <tr key={item.id}>
                      <td><img src={item.image} alt={item.name} className="h-10 w-10 inline"/> {item.name}</td>
                      <td>{item.notes.discpts}</td>
                      <td>{item.notes.obtain}</td>
                      <td>{item.notes.exist}</td>
                      <td>{item.notes.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table></div>
            </div></div>
          </section>
        )}
      </div>
      <BackToTopGlobal/>
    </div>
  );
};

export default LandSpeck;
