import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import pixelore from "../assets/images/guildwarspixelore.jpg";

const GuildWars = () => {
  const navigate = useNavigate();
  const { tabId } = useParams();
  const [activeTab, setActiveTab] = useState(tabId || "");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const tabs = [
    {
      id: "gw-summary",
      label: "Guild Wars 2: Spore Sports",
      content: `<h2 class="text-4xl font-eventHeading underline">Summary of the Event</h2>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Guild+Wars/image_2024-12-08_141117650.png" class="h-60 mb-2">
      The New Guild Wars is hitting us with a brand new structure. Compared to the previous event, this Guild Wars is expected to last 3 months, and only be open a few select times per week!
      <br/>On top of that, there's brand new items, new machines, new currencies, and new ways to win! So let's dive in.
      <h2 class="text-4xl font-eventHeading underline">How to Participate:</h2>
      <i class="fa-solid fa-1" style="color: #9a7997;"></i> First, you need to be a member of a guild (Pledged with a role)<br/>
      <i class="fa-solid fa-2" style="color: #9a7997;"></i> Head to the Guild Castle, enter the elevator to go down to the caves<br/>
      <i class="fa-solid fa-3" style="color: #9a7997;"></i> Use your PickAxe to break the rocks guarding the soil<br/>
      <i class="fa-solid fa-4" style="color: #9a7997;"></i> Buy Spores, Plant them, Water them, Defend them from other guilds<br/>
      <i class="fa-solid fa-5" style="color: #9a7997;"></i> Destroy Other players Spores using Goo or Speed up your own using Guano<br/>
      <i class="fa-solid fa-6" style="color: #9a7997;"></i> Each Spore Harvested will automatically add to your points (Gooing, watering, using guano, and planting all garner different points)
      <br/><br/>
      <span class="font-bold">Leaderboards update once every 30 minutes!</span><br/>
      <h2 class="text-4xl font-eventHeading underline">The Expected Schedule:</h2>
      <ul class="basicLists"><li>Tuesday: 3:00 UTC & 13:00 UTC</li><li>Wednesday: 3:00 UTC & 13:00 UTC</li><li>Friday: 3:00 UTC & 13:00 UTC</li><li>Saturday: 3:00 UTC & 13:00 UTC</li></ul>
      `,
    },
    {
      id: "guildtiers",
      label: "Guild Tiers",
      content: `<h2 class="text-4xl font-eventHeading underline">Tiers and Tier Breakdowns:</h2>
      <b>Tier 1:</b> Guild has less than 11 Members<br/>
      <b>Tier 2:</b> Guild Has 11 - 31 Members<br/>
      <b>Tier 3:</b> Guild Has 32 - 65 Members<br/>
      <b>Tier 4:</b> 65+ Members<br/><br/>

      Each tier will have their own arena to fight on. Guild Leaders count towards members.<br/>

      <h2 class="text-4xl font-eventHeading underline">Some things to take notice of!</h2><br/>
      <ul class="basicLists">      
      <li>Your Guild can earn for different scoreboards</li>
      <li>If your guild goes up a tier, you will earn for the higher tier scoreboard</li>
      <li>If you go back down a tier, you will earn in the lower tier scoreboard</li>
      <li>You can cycle out guild members by giving them a role, or taking away a role</li>
      <li>Your guild can earn second place in one tier, and first place in another tier, or whichever ranking your guild places for the different tiers if you cycle scoreboards</li>
      <li>That means the same guild member can also go participate for another guild, and come back to your own guild</li>
      <li>You will only compete against guilds of similar tier</li>
      </ul>`,
    },
    {
      id: "prizepool",
      label: "Prize Pool",
      content: `<h2 class="text-4xl font-eventHeading underline">Prize Pool Breakdown:</h2>
        <p>The total Prize Pool is <span class="text-lg font-bold">$4 Million USD</span> spread between the 3 months. The reward will be split into various categories:</p>
        <div class="flex flex-wrap justify-between">
        <div class="w-full md:w-1/3 overflow-x-auto">
          <table class="table1 mr-5">
            <thead>
              <tr><th>Type</th><th>Leaderboard</th><th>Period</th><th>Expected Prizes</th></tr>
            </thead>
            <tbody>
              <tr><td>Guild</td><td>Overall</td><td>Seasonal</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Guild</td><td>Overall</td><td>Weekly</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Overall</td><td>Seasonal</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Overall</td><td>Weekly</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Watering</td><td>Seasonal</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Watering</td><td>Weekly</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Guano</td><td>Seasonal</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Guano</td><td>Weekly</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Goo</td><td>Seasonal</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Goo</td><td>Weekly</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Spores</td><td>Seasonal</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
              <tr><td>Individual</td><td>Spores</td><td>Weekly</td><td><img src="/images/currencies/cur_guildrunes.png" class="h-5 inline"> Guild Runes<br/><img src="/images/currencies/cur_pixel.png" class="h-5 inline"> $PIXEL</td></tr>
            </tbody>
          </table>
        </div>

        <div class="w-full md:w-2/3">
        <h2 class="text-4xl font-eventHeading underline">How to win prizes:</h2><br/>
        <div class="text-xl underline">Guild Prizes</div>
        <a href="https://docs.google.com/spreadsheets/d/1S-zYOI4ZndqaRLe7IrYggNj47zaZY39Z_dwwEm5ky2g/edit?gid=0#gid=0" rel="noreferrer" target="_blank" class="text-retropink hover:text-black">Top 3 Guilds for each Tier will win Rewards Seasonally</a><br/>
        <a href="https://docs.google.com/spreadsheets/d/1S-zYOI4ZndqaRLe7IrYggNj47zaZY39Z_dwwEm5ky2g/edit?gid=0#gid=0" rel="noreferrer" target="_blank" class="text-retropink hover:text-black">Top 10 Guilds for each Tier will win rewards Weekly</a><br/>
        All $PIXEL will be sent to Guild Leaders on both Seasonal and Weekly cadence.<br/><br/>
        Additionally, top 10 guilds will receive Guild Runes which will be sent to each shard holder individually. This is only for the top 10 guilds of each tier, both seasonally and weekly.<br/>
        <br/>
        <div class="text-xl underline">Individual Prizes</div>
        <a href="https://docs.google.com/spreadsheets/d/1S-zYOI4ZndqaRLe7IrYggNj47zaZY39Z_dwwEm5ky2g/edit?gid=996803937#gid=996803937" rel="noreferrer" target="_blank" class="text-retropink hover:text-black">Overall Individual Rewards</a><br/>
        <span class="font-bold">Seasonal:</span> Top 3 in Rank per Season Will receive $PIXEL and Top 10 in Rank will receive Guild Runes<br/>
        <span class="font-bold">Seaonal:</span> Players above 320 Points will split 495,000 $PIXEL and 120 Guild Runes<br/>
        <span class="font-bold">Seasonal:</span> Players above 80 Points will split 495,000 $PIXEL and 80 Guild Runes<br/>
        <span class="font-bold">Seasonal:</span> Players above 15 Points will split 510,000 $PIXEL and 40 Guild Runes<br/>
        <br/>        
        <span class="font-bold">Weekly:</span> Top 10 in Rank Per Week <i>(in their division)</i> Will receive $PIXEL and Top 10 in Rank will receive Guild Runes<br/>
        <span class="font-bold">Weekly:</span> Players above 10,000 Points will split 57,750 $PIXEL and 15 Guild Runes<br/>        
        <span class="font-bold">Weekly:</span> Players above 1,800 Points will split 57,750 $PIXEL and 10 Guild Runes<br/>        
        <span class="font-bold">Weekly:</span> Players above 20 Points will split 59,500 $PIXEL and 5 Guild Runes<br/><br/>

        <a href="https://docs.google.com/spreadsheets/d/1S-zYOI4ZndqaRLe7IrYggNj47zaZY39Z_dwwEm5ky2g/edit?gid=39243526#gid=39243526" rel="noreferrer" target="_blank" class="text-retropink hover:text-black">Goo/Guano/Water/Spores</a><br/>          
        <span class="font-bold">Seasonal:</span> Top 10 in Rank will receive their own individual $PIXEL and Guild Runes<br/>
        <span class="font-bold">Seaonal:</span> Players between ranks 11-100 will split 14,625 $PIXEL and receive 24 Guild Runes<br/>
        <span class="font-bold">Seasonal:</span> Players between ranks 101-1000 will split 56,250 $PIXEL and receive 16 Guild Runes<br/>
        <span class="font-bold">Seasonal:</span> Players between ranks 1001-2000 will split 30,094 $PIXEL and receive 8 Guild Runes<br/>
        <br/>        
        <span class="font-bold">Weekly:</span> Top 10 in Rank will receive their own individual $PIXEL and Guild Runes<br/>
        <span class="font-bold">Weekly:</span> Players between ranks 11-100 will split 1,219 $PIXEL and receive 3 Guild Runes<br/>        
        <span class="font-bold">Weekly:</span> Players between ranks 101-1000 will split 4,688 $PIXEL and receive 2 Guild Runes<br/>        
        <span class="font-bold">Weekly:</span> Players between ranks 1001-2000 will split 2,508 $PIXEL and receive 1 Guild Rune<br/><br/>        
        </div>
      </div>

      <h2 class="text-4xl font-eventHeading underline">Other Prizes:</h2>
      <ul class="basicLists">
      <li>1st Rank Guilds will each receive Matching Guild Wardrobe Outfits per Member</li>
      <li>Top 1000 Goo Players will get a Goo Wardrobe Outfit</li>
      <li>Top 1000 Guano Players will receive a Guano Wardrobe Outfit</li>
      <li>Top 1000 Spores Players will receive a Spores Wardrobe Outfit</li>
      <li>Top 1000 Warering Players will receive a Watering Wardrobe Outfit</li>
      <li>Everyone who gets at least 100 Points on the Seasonal Individual Leaderboard will receive a Guild Wars Season 1 Decoration</li>
      <li>1st, 2nd and 3rd for each winning Guild will receive Trophies/Badges</li>
      </ul>

      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Guild+Wars/guildwarsoutfits.png" class="w-96"/>
    `,
    },
    {
      id: "divisions",
      label: "Divisions",
      content: `<h2 class="text-4xl font-eventHeading underline">Weekly & Seasonal Divisions:</h2>
      Each individual will be assigned a division both weekly and seasonally. (If you get put against a top $PIXEL spender, I feel sorry for you but have fun anyways!)<br/>
      <br/>In the beginning of the event, you'll get randomly placed into your division. For the season, you'll keep that division for the entire 12 weeks. But for the weekly one will change every week! So don't be discouraged if you have a hard week division.<br/>
      <br/>These are the players you will need to top rank against to be able to earn $PIXEL or Guild Runes! But there's a high chance to rank well against the 49 other members for that division. Wish you all the best of luck ╰(*°▽°*)╯`,
    },
    {
      id: "points",
      label: "Items & Points",
      content: `<h2 class="text-4xl font-eventHeading underline">Items & Points:</h2>
      <table class="table1">
        <thead>
          <tr>
            <th class="border px-4 py-2">Type</th>
            <th class="border px-4 py-2">Tier</th>
            <th class="border px-4 py-2">Cost</th>
            <th class="border px-4 py-2">Time</th>
            <th class="border px-4 py-2">Energy</th>
            <th class="border px-4 py-2">Points</th>
            <th class="border px-4 py-2">Dry Amount</th>
            <th class="border px-4 py-2">Grow Speed</th>
          </tr>
        </thead>
        <tbody>
          <!-- Spore Rows -->
          <tr>
            <td class="border px-4 py-2" rowspan="4">Spores</td>
            <td class="border px-4 py-2">Tier 1</td>
            <td class="border px-4 py-2"><s>200</s> 400 Coins</td>
            <td class="border px-4 py-2">2.5 Minutes</td>
            <td class="border px-4 py-2">1</td>
            <td class="border px-4 py-2">1</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">-</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 2</td>
            <td class="border px-4 py-2"><s>1000</s> 1500 Coins</td>
            <td class="border px-4 py-2">2 Minutes</td>
            <td class="border px-4 py-2">2</td>
            <td class="border px-4 py-2">3</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">-</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 3</td>
            <td class="border px-4 py-2"><s>3</s> 2 $PIXEL</td>
            <td class="border px-4 py-2">1.5 Minutes</td>
            <td class="border px-4 py-2">3</td>
            <td class="border px-4 py-2"><s>5</s> 10</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">-</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 4</td>
            <td class="border px-4 py-2">Mixer</td>
            <td class="border px-4 py-2">1 Minute</td>
            <td class="border px-4 py-2">5</td>
            <td class="border px-4 py-2"><s>10</s> 25</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">-</td>
          </tr>

          <!-- Goo Rows -->
          <tr>
            <td class="border px-4 py-2" rowspan="4">Goo</td>
            <td class="border px-4 py-2">Tier 1</td>
            <td class="border px-4 py-2"><s>200</s> 400 Coins</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">5</td>
            <td class="border px-4 py-2">1</td>
            <td class="border px-4 py-2">5</td>
            <td class="border px-4 py-2">-</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 2</td>
            <td class="border px-4 py-2"><s>700</s> 1000 Coins</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">7.5</td>
            <td class="border px-4 py-2">3</td>
            <td class="border px-4 py-2">15</td>
            <td class="border px-4 py-2">-</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 3</td>
            <td class="border px-4 py-2">2.5 $PIXEL</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">10</td>
            <td class="border px-4 py-2"><s>5</s> 10</td>
            <td class="border px-4 py-2">50</td>
            <td class="border px-4 py-2">-</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 4</td>
            <td class="border px-4 py-2">Mixer</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">50</td>
            <td class="border px-4 py-2"><s>10</s> 25</td>
            <td class="border px-4 py-2">100</td>
            <td class="border px-4 py-2">-</td>
          </tr>

          <!-- Guano Rows -->
          <tr>
            <td class="border px-4 py-2" rowspan="4">Guano</td>
            <td class="border px-4 py-2">Tier 1</td>
            <td class="border px-4 py-2"><s>200</s> 400 Coins</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">1</td>
            <td class="border px-4 py-2">1</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">Little Boost</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 2</td>
            <td class="border px-4 py-2"><s>600</s> 900 Coins</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">3</td>
            <td class="border px-4 py-2">3</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">Medium Boost</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 3</td>
            <td class="border px-4 py-2"><s>1</s> 1.5 $PIXEL</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">5</td>
            <td class="border px-4 py-2"><s>5</s> 10</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">High Boost</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">Tier 4</td>
            <td class="border px-4 py-2">Mixer</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">8</td>
            <td class="border px-4 py-2"><s>10</s> 25</td>
            <td class="border px-4 py-2">-</td>
            <td class="border px-4 py-2">Super High Boost</td>
          </tr>
        </tbody>
      </table>      `,
    },
    {
      id: "machines",
      label: "New Machines!",
      content: `<h2 class="text-4xl font-eventHeading underline">T4 Ultimate Items Machines:</h2>
      There are three brand new machines for the Guild Wars events. These machines can be used once every 24 hours (Unless you have guild charms)<br/>
      The machines are: Guano Mixer, Spore Chamber and the Goo Pot, and as their name says, they will give you T4 Guano, Spore or Goo!
      <br/>
      <div class="text-center">
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Guild+Wars/GooPotEatingAnim.png" class="w-36 mx-10 inline"/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Guild+Wars/Mixer.png" class="w-36  mx-10 inline"/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Guild+Wars/guanomachine.png" class="w-36  mx-10 inline"/></div>
      <br/>
      <span class="font-bold">How does it work?</span>
      <br/><i class="fa-solid fa-1" style="color: #9a7997;"></i> Add 8 T2 or T3 of the equivalent item (Goo in Goo Pot, Spore in Spore Chamber, etc) <br/>
        Using T2 Items will return T3 Items and using T3 items will return T4 Items.<br/>
       <i class="fa-solid fa-2" style="color: #9a7997;"></i> Activate the machine, then start adding in resources - higher quality and quantity will create more items<br/>
       <i class="fa-solid fa-3" style="color: #9a7997;"></i> You can use resources like ores, crops, or any crafted items you have around!<br/>
      `,
    },
    {
      id: "runes",
      label: "Guild Runes",
      content: `<h2 class="text-4xl font-eventHeading underline">Guild Runes:</h2>
      What can you buy with Guild Runes? There will be different items depending on the rotating store.<br/>
      <ul class="basicLists">
      <li>Energy Drinks</li>
      <li>Guild Wars Charms (to reset the mixers)</li>
      <li>Decorative Items (I will detail these when we see more!)</li>
      <li>Utility Items (I will detail these as well when we see more!)</li></ul>
      `,
    },
    {
      id: "howtoplaygw",
      label: "How to Play",
      content: `<h2 class="text-4xl font-eventHeading underline">How do you Play:</h2>
      Buy Spores from the shop in the Guild Cave, and possibly some fertilizer too (Guano).<br/>
      Each spore you successfully harvest will grant you points towards the leaderboard. If you fertilize, you also earn points, and on top of that, you will speed up the growth time for your harvest!<br/>
      When you harvest your spore, the points will be automatically calculated towards the leaderboard for your individual LB, and the guild's LB.<br/>
      If someone comes around and tries to GOO your Spore, you need to water as quickly as you can to defend your Spore from dying. The Goo will reduce how much water is on your crop until it dies.<br/>
      You can also Goo someone else's spore, and stop them from growing their spores (tip: do this to their T4 Spores 😉)<br/>
      You will need multiple goo to kill someone's spore depending on how much water they've put. But you can just as easily defend against their gooing attempts if you water quickly. Stay on defense!<br/><br/>
      <b>Gooing, Planting, Watering and Fertilizing</b> will all earn you points on the leaderboard.
      `,
    },
  ];

  useEffect(() => {
    setActiveTab(tabId || ""); // Set the tab based on the URL parameter
  }, [tabId]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    navigate(`/game-wiki/events/${id}`);  // Update the URL when a tab is clicked
  };

  const getContent = (id) => {
    const tab = tabs.find((tab) => tab.id === id);
    return tab ? tab.content : `Please select a menu item on the left to view content.`;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="bg-retropb rounded-md p-2 mt-2 relative">
      <h2 className="starterTitle">Guild Wars 2: Spore Wars!</h2>
      <div className="starterText">
        {/* Mobile toggle button */}
        <div className="block md:hidden">
          <button onClick={toggleSidebar} className="p-1 bg-retrogray text-white rounded text-xs m-2">
            {isSidebarOpen ? "-" : "+"}
          </button>
        </div>

        {/* Sidebar and Backdrop */}
        {isSidebarOpen && (
          <>
            {/* Backdrop to cover content when sidebar is open */}
            <div className="fixed inset-0 bg-black opacity-60 z-40" onClick={toggleSidebar}></div>

            {/* Sidebar */}
            <div
              className="fixed top-30 align-middle left-0 h-2/4 w-3/4 bg-retrogray text-white z-50 p-4 border-r border-gray-300"
              style={{ maxWidth: "250px" }}
            >
              <ul>
                {tabs.map((tab) => (
                  <li key={tab.id}>
                    <button
                      className={`tab-button ${tab.id === activeTab ? "active" : ""}`}
                      onClick={() => {
                        handleTabClick(tab.id);
                        toggleSidebar(); // Close sidebar after selecting a tab
                      }}
                    >
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        <div className="flex">
          {/* Sidebar for desktop */}
          <div className="hidden md:block w-[200px] border-r border-gray-300 pr-2 mr-2">
            <ul>
              {tabs.map((tab) => (
                <li key={tab.id}>
                  <button
                    className={`tab-button w-full text-left p-2 rounded-md transition-colors duration-200 m-1 ${
                      tab.id === activeTab
                        ? "bg-gray-500 text-white" // Active tab styles
                        : "bg-retrobg text-white hover:bg-gray-400" // Inactive tab styles
                    }`}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Content Area */}
          <div className="w-full md:ml-4">
            {activeTab ? (
              // Render content for other tabs
              <div dangerouslySetInnerHTML={{ __html: getContent(activeTab) }} />
            ) : (
              // Default content if no tab is selected
              <div>
                <img src={pixelore} alt="Pixelore" className="h-96 rounded-3xl" />
                <p className="text-lg mt-2">Please select a menu item on the left to view content.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuildWars;
