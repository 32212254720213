import React, { useState, useEffect } from "react";
import pixelore from "../assets/images/pixelore-christmas.jpeg";
import { useNavigate, useParams } from "react-router-dom";

const Christmas = () => {
  const navigate = useNavigate();
  const { tabId } = useParams();
  const [activeTab, setActiveTab] = useState(tabId || "");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const tabs = [
    {
      id: "ch-summary",
      label: "Event Summary",
      content: `<h2 class="text-4xl font-eventHeading underline">Summary of the Event</h2>
      <br/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/Christmas.png" alt="Christmas map" class="h-96 mb-5"/>
      It's <span class="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-green-600 to-red-900">
        CHRISTMAS CARNIVAL TIME!
      </span><br/>And with that, comes Pixmas and all the jolly good things to come with it. For the Christmas Carnival Event, we're getting mini-games, prize stands and a whole lot of goodies.<br/>
      <br/>Of course, I am here to walk you through it all.<br/>
      What you'll need is Cookie Credits, and each game earns you a different token that can be used in the equivalent shops. Check out the left side panel to get deeper into each game, and more details on the other stuff like the shop prizes, and where to go for each game!<br/>
      <br/><span class="underline text-2xl">Tips on Games:</span>
      <br/><ul class="basicLists">
      <li>Crack The Code - Puzzle Game - Red Mistletokens</li>
      <li>Candy Chaos - Farming Game - Red Mistletokens</li>
      <li>Polar Puzzler - Puzzle Game - Green Mistletokens</li>
      <li>Squish a Fish - Skill Game - Green Mistletokens</li>
      <li>Present Pusher - Puzzle Game - White Mistletokens</li>
      <li>Santa's Speck - DIY Game - White Mistletokens</li>
      </ul>      
      `,
    },
    {
      id: "ch-start",
      label: "How to Start",
      content: `<h2 class="text-4xl font-eventHeading underline">How do I start</h2><br/>
      When you first enter the Christmas Arena, talk to <b>Doug Fir</b>. He'll get you a Christmas Tree that you can plant on your land! Also head up and click on the Cookie Machine to claim your 5 free cookies for the day.
      You will need those cookies to play games.<br/>
      You can buy more from <b>Margaret</b> for $PIXEL, but these are the free ones you earn per day.<br/>
      Once you've collected your Cookies, go plant your Pixmas Tree and then talk to Doug Fir again for 1 free cookie!<br/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/%7B468949A7-9DF4-407C-85DA-09F5342388EA%7D.png" alt="christmas entry" class="h-96"/>`,
    },
    {
      id: `ch-cookies`,
      label: `How to Get Cookies`,
      content: `<h2 class="text-4xl font-eventHeading underline">How do I Get Cookies</h2><br/>
      🍪 You get one cookie from Planting Your Tree on a land<br/>
      🍪 Claim 5 Free Cookies daily at the Cookie Machine<br/>
      🍪 1 Cookie from Redeeming 33 OG Tickets at Luke every 24h (Santa's Speck)<br/>
      🍪 25 Cookies for 4 $PIXEL from Granny's Cookie Shop (1 per day)<br/>
      🍪 5 Cookies for 1 $PIXEL from Granny's Cookie Shop (2x per day)<br/>
      🍪 30 Cookies from each Starter Stocking (Red, Green and White)<br/>`,
    },
    {
      id: `ch-prizes`,
      label: `How to Get Grand Prizes`,
      content: `<h2 class="text-4xl font-eventHeading underline">How do I Get The Grand Prizes</h2><br/>
      🎁 99 <b>Pixel Pets</b> Between Mistletoken Gacha Boxes <br/>
      🎁 101 <b>Pixel Pets</b> in Pixmas Tree Completion Boxes<br/>
      🎁 100 <b>Pixel Pets</b> from Final Week Gacha<br/>
      🎁 400 <b>Farming Ice Sculptures</b> from $PIXEL Gacha <br/>
      🎁 400 <b>Mining Ice Sculptures</b> from $PIXEL Gacha<br/>
      🎁 400 <b>Forestry Ice Sculptures</b> from $PIXEL Gacha<br/>
      🎁 200 <b>Woodworking Ice Sculptures</b> from Green Mistletoken Gift Box<br/>
      🎁 200 <b>Metalworking Ice Sculptures</b> from Red Mistletoken Gift Box<br/>
      🎁 200 <b>Stoneshaping Ice Sculptures</b> from White Mistletoken Gift Box<br/>
      🎁 60 <b>Mill Ice Sculptures</b> from Pixmas Tree Gacha<br/>
      🎁 60 <b>Winemaking Ice Sculptures</b> from Pixmas Tree Gacha<br/>
      🎁 60 <b>Textilemaking Ice Sculptures</b> from Pixmas Tree Gacha<br/>
      🎁 ? <b>Gift Wrapped Quonset Hut</b> from $PIXEL Gacha and Pixmas Tree Gacha`,
    },
    {
      id: "ch-pixmastree",
      label: "Pixmas Tree",
      content: `<h2 class="text-4xl font-eventHeading underline">Pixmas Tree</h2><br/>
      The Pixmas Tree is a yearly tradition at Pixels, and this year is no different. Finishing the tree this year gives you a chance to win grand prizes, so try to complete it quick!<br/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-17_190732160.png" alt="Pixmas Tree" class="h-96"/><br/>
      Each ornament can be found in the Rotating Stores, and can also be found from gift boxes. ($PIXEL, Coin and Mistletoken Gift Boxes)<br/><br/>
      There are 14 ornaments in total, and you can get the tree from <b>Doug Fir</b>!<br/>
      All ornaments are tradable except the Pixmas Tree Star<br/>
      The tree is also not tradable (neither complete nor empty)<br/>
      If you complete the tree on someone else's land, they'll be able to collect the prizes and you wont be able to get them.<br/>
      <ul class="basicLists">
      <li>String Lights (From the Golden Gift Box - Random)</li>
      <li><span class="bg-white text-black">Pixmas Tinsel Garrland (200 White Mistletokens) </span></li>
      <li><span class="bg-white text-black">Whale Ornament (600 White Mistletokens)</span></li>
      <li><span class="bg-white text-black">Hunkin' Heifer Ornament (400 White Mistletokens OR $PIXEL Gacha)</span></li>
      <li><span class="bg-white text-black">Pixmas Penguin Ornament (1,200 White Mistletokens)</span></li>
      <li><span class="bg-red-900 text-white">Gingerbread Dude Ornament (600 Red Mistletokens)</span></li>
      <li><span class="bg-red-900 text-white">Cute Lil' Ball Ornament (200 Red Mistletokens)</span></li>
      <li><span class="bg-red-900 text-white">Bazaarn Ornament (1,200 Red Mistletokens)</span></li>
      <li><span class="bg-red-900 text-white">Golden Clam Ornament (400 Red Mistletokens OR $PIXEL Gacha)</span></li>
      <li><span class="bg-green-400 text-white">CEOrnament (1,200 Green Mistletokens)</span> </li>
      <li><span class="bg-green-400 text-white">Goo Ornament (200 Green Mistletokens)</span></li>
      <li><span class="bg-green-400 text-white">$PIXEL Crystal Ornament (400 Green Mistletokens)</span></li>
      <li><span class="bg-green-400 text-white">Heidi Ornament (600 Green Mistletokens)</span></li>
      <li><span class="font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-green-600 to-red-900">Pixmas Tree Star (1,600 Green, Red or White Mistletokens OR $PIXEL Gacha)</span></li>
      </ul>
      `,
    },
    {
      id: "ch-game1",
      label: "How to Play: Crack the Code",
      content: `<h2 class="text-4xl font-eventHeading underline">Crack the Code</h2>
      <br/>
      <b>Credits To Play:</b> 2 Cookie Credits<br/>
      <b>Objective:</b> Crack the Code Quick! The faster you crack the code, the more mistletokens you earn.<br/>Also there's a multiplier if you have a streak! You get more tokens based on how big your streak is.<br/>
      <b>Time Between Games:</b> 3 Minutes<br/>
      <b>Reward:</b> Red Mistletokens<br/>1 - 72 Mistletokens<br/>2 - <br/>3 - 43 Mistletokens<br/>4 - 29 Mistletokens<br/>5 - 14 Mistletokens<br/><br/>
      <b>Rules of the Game:</b><br/>
      <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/o/obj_htp_crackthecode/e21bd4f5-7707-47c6-95fa-3ce02a5605fa.svg?v6" alt="htp-crackthecode" />`,
    },
    {
      id: "ch-game2",
      label: "How to Play: Candy Chaos",
      content: `<h2 class="text-4xl font-eventHeading underline">Candy Chaos</h2>
      <br/>
      <b>Credits To Play:</b> 5 Cookie Credits<br/>
      <b>Time Between Games:</b> 3 Minutes<br/>
      <b>Objective:</b> Plant as Many Candy Canes as possible to earn points<br/>Make sure you buy the Choco Sauce from the Shop first! You will need it to water your candy canes. <br/>
      <b>Reward:</b> Red Mistletokens<br/>1 Mistletoken Per Point Earned<br/>
      <b>Rules of the Game:</b><br/>
      <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/o/obj_htp_candychaos/7343b79e-34a2-480a-a5c9-cf579b56ab7b.svg?v6" alt="htp-candychaos" />`,
    },
    {
      id: "ch-game3",
      label: "How to Play: Squish a Fish",
      content: `<h2 class="text-4xl font-eventHeading underline">Squish a Fish</h2>
      <br/>
      <b>Credits To Play:</b> 2 Cookie Credits<br/>
      <b>Time Between Games:</b> 3 Minutes<br/>
      <b>Objective:</b> Click as Many of the Fishes as possible to earn points. Each point is a mistletoken!<br/>
      <b>Reward:</b> Green Mistletokens<br/>1pt = 1 Mistletoken<br/>
      <b>Rules of the Game:</b><br/>
      <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/o/obj_htp_squishthefish/18f033da-4857-46e8-8ed3-8411ef397d09.svg?v6" alt="htp-squishafish" />
      `,
    },
    {
      id: "ch-game4",
      label: "How to Play: Polar Puzzler",
      content: `<h2 class="text-4xl font-eventHeading underline">Polar Puzzler</h2>
      <br/>
      <b>Credits To Play:</b> 3 Cookie Credits<br/>
      <b>Time Between Games:</b> Once per day<br/>
      <b>Objective:</b> Slide across the pieces of the puzzle until it matches the original image! Will upload sample images for each puzzle!<br/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-27_192634297.png" alt="Sample Puzzle" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/%7BB2FB9948-0A42-4B79-812A-322DCD9A1277%7D.png" alt="Sample Puzzle 2" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/%7B8F12EE9A-D1B8-40C7-B517-9A18ED41EDB1%7D.png" alt="Sample Puzzle 3" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/%7B0275E5D6-3242-425D-9874-7294A171823B%7D.png" alt="Sample Puzzle 4" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-22_014614978.png" alt="Sample Puzzle 5" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-23_014903487.png" alt="Sample Puzzle 6" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-24_131436384.png" alt="Sample Puzzle 7" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-24_190310740.png" alt="Sample Puzzle 8" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-25_185911450.png" alt="Sample Puzzle 9" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-27_020258446.png" alt="Sample Puzzle 10" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-27_192402552.png" alt="Sample Puzzle 11" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-28_192926166.png" alt="Sample Puzzle 12" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-29_185844488.png" alt="Sample Puzzle 13" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2024-12-30_193846349.png" alt="Sample Puzzle 14" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2025-01-01_202451765.png" alt="Sample Puzzle 15" class="h-36 inline" />
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/image_2025-01-01_202534811.png" alt="Sample Puzzle 16" class="h-36 inline" />
      <br/>
      <b>Reward:</b> Green Mistletokens (amount dependent on how fast you solve it (●'◡'●) )<br/>1000 Mistletokens if you complete the puzzle in less than 100 moves<br/>
      Rewards were changed to be 1000, 500, 333 tokens in decreasing amounts depending on how many times you solve it. <br/>
      <b>Rules of the Game:</b><br/>
      <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/o/obj_htp_polarpuzzler/2c0b6068-3538-4a35-8eed-b9087329b95f.svg?v6" alt="htp-polarpuzzler" />
        `,
    },
    {
      id: "ch-game5",
      label: "How to Play: Present Pusher",
      content: `<h2 class="text-4xl font-eventHeading underline">Present Pusher</h2>
      <br/>
      <b>Credits To Play:</b> 3 Cookie Credits<br/>
      <b>Time Between Games:</b> 3 Minutes<br/>
      <b>Objective:</b> Get all the presents down the chimney without getting stuck. You can get stuck if there's no other way to move the presents.<br/>
      <b>Reward:</b> White Mistletokens<br/>
      <b>Rules of the Game:</b><br/>
      <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/o/obj_htp_slushyslider/7b3cbc1a-ca3d-4714-ad6c-44c404fda4f3.svg?v6" alt="htp-presentpusher" />
        `,
    },
    {
      id: "ch-game6",
      label: "How to Play: Santa's Speck",
      content: `<h2 class="text-4xl font-eventHeading underline">Santa's Speck</h2>
      <br/>
      <b>Credits To Play:</b> <br/>
      <b>Time Between Games:</b> 3 Minutes<br/>
      <b>Objective:</b> Complete Tasks on the special speck task board (3 total per day - 2 Regular and 1 extra for VIP). Each task can have its own reward<br/>
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/%7BE7E18D44-F24D-4DC4-A06C-705C74782381%7D.png" alt="Sample Task Board" /><br/>
      <b>Toy Teddy</b> - (Crafted at the Toy Making Station) - 9 Wool Wad, 3 Silk Cloth, 1 Turkey Feature and 1 $PIXEL Crystal Ornament<br/>
      <b>Pixels Ambrosia</b> - (Crafted at the Shake Stand) - 1 Cow Milk, 1 Popberry Wine, 1 Silk Slug Slime, 6 Blue Grumpkin, 1 Ice Chunk<br/>
      <b>Eggspresso Delight</b> - (Crafted at the Pixpresso Stand) - 1 Cow Milk, 1 Grainshine, 2 Insta-Java Packet, 1 Turkey Egg, 1 Ice Chunk<br/>
      <b>Snowbody’s Prefect Sweater</b> - (Crafted at the Textiling Station) - 3 Wool Wad, 3 Turkey Feather, 12 Clover<br/>
      <b>Love At Frost Sight Sweater</b> - (Crafted at the Textiling Station) - 3 Wool Wad, 3 Turkey Feather, 6 Grainbow Flour<br/>
      <b>Fleece Navidad Sweater</b> - (Crafted at the Textiling Station) - 3 Wool Wad, 3 Turkey Feather, 12 Gravelglass Matrix<br/>
      <b>What The Elf? Sweater</b> - (Crafted at the Textiling Station) - 3 Wool Wad, 3 Turkey Feather, 12 Sawdust<br/><br/>
      
      <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Christmas+Event/SANTA+SPECK.png" alt="Santa's Speck" class="h-92" /><br/>

      🐏 - <b>Wool Wad</b> - Give the Sheep Chocco Milk (can be bought from the Shop) and it will drop wool (10m)<br/>
      🦃 - <b>Turkey Feather</b> - Give the turkey Grainbow Flour and it will drop Turkey Eggs and Turkey Feathers (1h20m)<br/>
      🐄 - <b>Cow Milk</b> - Give the cow MooMunch and it will drop Cow Turd and Cow Milk (1h 20m)<br/><br/>

      Also in Santa's Speck House is the option to Redeem old game ticket carnival tickets. You can spin the machin every 24 hours with 33 Game Tickets to redeem 1 Cookie Credit!
      <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/i/itm_gameticket/8ff0a7c1-c8e2-42b9-b1fd-4e5aedbceea6.png" alt="Game Ticket" class="h-20" /><br/><br/>
      The bed does not seem to work and doesn't give 50 energy <(＿　＿)><br/>
      <b>Reward:</b> White Mistletokens<br/>
      <b>Rules of the Game:</b><br/>
      <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/o/obj_htp_santaspeck/2a6f5ba1-b43a-4c04-b1ce-e557d21dbee2.svg?v6" alt="htp-santasspeck" />
        `,
    },
    {
      id: "hw-currency",
      label: "Prize Trailers & Stores",
      content: `<h2 class="text-4xl font-eventHeading underline">Prize Trailers & Winter Wonderland Store</h2>
      <br/>
      <div class="flex flex-wrap justify-center gap-2">
      <div class="p-2 w-full md:w-2/5 md:mb-1 mb-2 bg-red-800 text-center outline outline-gray-600 outline-dashed outline-2 rounded-lg"><h2 class="text-2xl font-teko underline text-white">Red Mistletoken Store (Rotating)</h2>
      <br/>
      <div class="overflow-x-auto rounded-md  text-retrosilver">
        <table class="table-auto w-full border-collapse">
          <thead>
            <tr class="border-b border-gray-100 group">
              <th class="px-4 py-2 text-center">Item</th>
              <th class="px-4 py-2 text-left">Price</th>
              <th class="px-4 py-2 text-center">Week</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b border-gray-100 group">
              <td class="px-2 py-1 relative">
                <span class="cursor-pointer">Red Gift Box</span>
                <div
                  class="absolute left-3/4 -translate-x-1/2 bottom mb-1 hidden w-96 bg-black text-white text-sm text-left rounded-md py-2 px-4 opacity-0 group-hover:opacity-100 group-hover:block transition-opacity"
                >
                  → Metalworking Ice Sculpture<br/>
                  → Pet Coupon (Penguin Pet - 400 total)<br/>
                  → Pixmas Cheer Wallpaper (1-3)<br/>
                  → Ice Chunk (can be used in Santa's Speck Game)<br/>
                  → Peppermint Dreams Blanket<br/>
                  → Peppermint Power Seed (1-3) (for the Candy Chaos Game)<br/>
                  → Evergreen Swirl Seed(for the Candy Chaos Game)<br/>
                  → Snowy Rocks (Decor)<br/>
                  → Snowy Hefty Bush<br/>
                  → Snowy Symmetrical Bush<br/>
                  → Recipe: What The Elf? Sweater (can be used in Santa's Speck Game)<br/>
                  → Wool Wad (1-3) (can be used in Santa's Speck Game)
                </div>
              </td>
              <td class="px-2 py-1 text-left">160</td>
              <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Cute Lil' Ball Ornament</td>
               <td class="px-2 py-1 text-left">200</td>
               <td class="px-2 py-1">1</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Pixmas Street Light</td>
               <td class="px-2 py-1 text-left">400</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Gingerbread Dude Ornament</td>
               <td class="px-2 py-1 text-left">600</td>
               <td class="px-2 py-1">1</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Bazaarn Ornament</td>
               <td class="px-2 py-1 text-left">1,200</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Golden Clam Ornament</td>
               <td class="px-2 py-1 text-left">400</td>
               <td class="px-2 py-1">2</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Terra Villa Winter Statue</td>
               <td class="px-2 py-1 text-left">900</td>
               <td class="px-2 py-1">2 - 3</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Gingerbread Speck House Skin</td>
               <td class="px-2 py-1 text-left">1,500</td>
               <td class="px-2 py-1">3</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Pixmas Tree Star ('24)</td>
               <td class="px-2 py-1 text-left">1,900</td>
               <td class="px-2 py-1">3</td>
            </tr>
            <tr class="border-b border-gray-100">
               <td class="px-2 py-1">Metalworking Ice Sculpture<br/>(Stock: 50)</td>
               <td class="px-2 py-1 text-left">9,660</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
          </tbody>
        </table>
      </div></div>

      <div class="p-2 w-full md:w-2/5 md:mb-1 mb-2 bg-green-900 text-white text-center outline outline-gray-600 outline-dashed outline-2 rounded-lg"><h2 class="text-2xl font-teko underline">Green Mistletoken Store (Rotating)</h2>
      <br/>
      <div class="overflow-x-auto rounded-md text-white">
        <table class="table-auto w-full border-collapse">
          <thead>
            <tr class="border-b border-gray-200">
              <th class="px-4 py-2 text-center">Item</th>
              <th class="px-4 py-2 text-left">Price</th>
              <th class="px-4 py-2 text-center">Week</th>
            </tr>
          </thead>
          <tbody>
          <tr class="border-b border-gray-100 group">
              <td class="px-2 py-1 relative">
                <span class="cursor-pointer">Green Gift Box</span>
                <div
                  class="absolute left-3/4 -translate-x-1/2 bottom mb-1 hidden w-96 bg-black text-white text-sm text-left rounded-md py-2 px-4 opacity-0 group-hover:opacity-100 group-hover:block transition-opacity"
                >
                  → Woodworking Ice Sculpture<br/>
                  → Pet Coupon (Penguin Pet - 400 total)<br/>
                  → Gift Wrap Wallpaper (1-3)<br/>
                  → Ice Chunk (can be used in Santa's Speck Game)<br/>
                  → Frosted Forest Bedcover<br/>
                  → Peppermint Power Seed (1-3) (for the Candy Chaos Game)<br/>
                  → Evergreen Swirl Seed(for the Candy Chaos Game)<br/>
                  → Snowy Lithe Bush (Decor)<br/>
                  → Recipe: Snowbody's Prefect Sweater (can be used in Santa's Speck Game)<br/>
                  → Snowy Simple Bush (Decor)<br/>
                  → Wool Wad (can be used in Santa's Speck Game)
                </div>
              </td>
              <td class="px-2 py-1 text-left">320</td>
              <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-gray-200">
               <td class="px-2 py-1">Snowy Picnic Bench</td>
               <td class="px-2 py-1 text-left">400</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-gray-200">
               <td class="px-2 py-1">Heidi Ornament ('24)</td>
               <td class="px-2 py-1 text-left">600</td>
               <td class="px-2 py-1">1</td>
            </tr>
            <tr class="border-b border-gray-200">
               <td class="px-2 py-1">CEOrnament ('24)</td>
               <td class="px-2 py-1 text-left">1200</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Gingerbread Chest</td>
               <td class="px-2 py-1 text-left">800</td>
               <td class="px-2 py-1">2 - 3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">$PIXEL Christmas Ornament ('24)</td>
               <td class="px-2 py-1 text-left">400</td>
               <td class="px-2 py-1">3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Pixmas Cozy Fireplace</td>
               <td class="px-2 py-1 text-left">1,800</td>
               <td class="px-2 py-1">3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Pixmas Tree Star</td>
               <td class="px-2 py-1 text-left">2,800</td>
               <td class="px-2 py-1">3</td>
            </tr>
            <tr class="border-b border-gray-200">
               <td class="px-2 py-1">Woodworking Ice Sculpture<br/>(50 Stock)</td>
               <td class="px-2 py-1 text-left">24,500</td>
               <td class="px-2 py-1">1 -2 - 3</td>
            </tr>
          </tbody>
        </table>
      </div></div>
      
      <div class="p-2 w-full md:w-2/5 md:mb-1 mb-2 bg-christmaswhite text-center outline outline-gray-600 outline-dashed outline-2 rounded-lg"><h2 class="text-2xl font-teko underline">White Mistletoken Store (Rotating)</h2>
      <br/>
      <div class="overflow-x-auto rounded-md text-retropb">
        <table class="table-auto w-full border-collapse">
          <thead>
            <tr class="border-b border-black">
              <th class="px-4 py-2 text-center">Item</th>
              <th class="px-4 py-2 text-left">Price</th>
              <th class="px-4 py-2 text-center">Week</th>
            </tr>
          </thead>
          <tbody>
          <tr class="border-b border-gray-100 group">
              <td class="px-2 py-1 relative">
                <span class="cursor-pointer">White Gift Box</span>
                <div
                  class="absolute left-3/4 -translate-x-1/2 bottom mb-1 hidden w-96 bg-black text-white text-sm text-left rounded-md py-2 px-4 opacity-0 group-hover:opacity-100 group-hover:block transition-opacity"
                >
                  → Stoneshaping Ice Sculpture<br/>
                  → Pet Coupon (Penguin Pet - 400 total)<br/>
                  → Holly Joy Wallpaper (1-3)<br/>
                  → Ice Chunk (can be used in Santa's Speck Game)<br/>
                  → Peppermint Power Seed (1-3) (for the Candy Chaos Game)<br/>
                  → Evergreen Swirl Seed (for the Candy Chaos Game)<br/>
                  → Snowy Imposing Bush (Decor)<br/>
                  → Recipe: Fleece Navidad Sweater (can be used in Santa's Speck Game)<br/>
                  → Snowy Bench (Decor)
                </div>
              </td>
              <td class="px-2 py-1 text-left">240</td>
              <td class="px-2 py-1">1 -2 - 3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Recipe: Love At Frost Sight Sweater</td>
               <td class="px-2 py-1 text-left">50</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Recipe: Frosted Flake Sweater</td>
               <td class="px-2 py-1 text-left">300</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Snowman Decoration</td>
               <td class="px-2 py-1 text-left">400</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Hunkin' Heifer Ornament ('24)</td>
               <td class="px-2 py-1 text-left">400</td>
               <td class="px-2 py-1">1</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Whale Ornament ('24)</td>
               <td class="px-2 py-1 text-left">600</td>
               <td class="px-2 py-1">1</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Choco Fountain</td>
               <td class="px-2 py-1 text-left">800</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Pixmas Penguin Ornament ('24)</td>
               <td class="px-2 py-1 text-left">1,200</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
             <tr class="border-b border-black">
               <td class="px-2 py-1">Pixmas Cozy Bedrame</td>
               <td class="px-2 py-1 text-left">1,000</td>
               <td class="px-2 py-1">2 - 3</td>
            </tr>
             <tr class="border-b border-black">
               <td class="px-2 py-1">Pixmas Tree Star ('24)</td>
               <td class="px-2 py-1 text-left">1,200</td>
               <td class="px-2 py-1">3</td>
            </tr>
            <tr class="border-b border-black">
               <td class="px-2 py-1">Stoneshaping Ice Sculpture<br/>(Stock: 50)</td>
               <td class="px-2 py-1 text-left">11,340</td>
               <td class="px-2 py-1">1 - 2 - 3</td>
            </tr>
          </tbody>
        </table>
      </div></div>
      
      </div>`,
    },
  ];

  useEffect(() => {
    setActiveTab(tabId || "");
  }, [tabId]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    navigate(`/game-wiki/events/${id}`); // Update the URL when a tab is clicked
  };

  const getContent = (id) => {
    const tab = tabs.find((tab) => tab.id === id);
    return tab ? tab.content : `Please select a menu item on the left to view content.`;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="bg-retropb rounded-md p-2 mt-2 relative">
      <h2 className="starterTitle">Christmas Event</h2>
      <div className="starterText">
        {/* Mobile toggle button */}
        <div className="block md:hidden">
          <button onClick={toggleSidebar} className="p-1 bg-retrogray text-white rounded text-xs m-2">
            {isSidebarOpen ? "-" : "+"}
          </button>
        </div>

        {/* Sidebar and Backdrop */}
        {isSidebarOpen && (
          <>
            {/* Backdrop to cover content when sidebar is open */}
            <div className="fixed inset-0 bg-black opacity-60 z-40" onClick={toggleSidebar}></div>

            {/* Sidebar */}
            <div
              className="fixed top-30 align-middle left-0 h-2/4 w-3/4 bg-retrogray text-white z-50 p-4 border-r border-gray-300"
              style={{ maxWidth: "250px" }}
            >
              <ul>
                {tabs.map((tab) => (
                  <li key={tab.id}>
                    <button
                      className={`tab-button ${tab.id === activeTab ? "active" : ""}`}
                      onClick={() => {
                        handleTabClick(tab.id);
                        toggleSidebar(); // Close sidebar after selecting a tab
                      }}
                    >
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        <div className="flex">
          {/* Sidebar for desktop */}
          <div className="hidden md:block w-[200px] border-r border-gray-300 pr-2 mr-2">
            <ul>
              {tabs.map((tab) => (
                <li key={tab.id}>
                  <button
                    className={`tab-button w-full text-left p-2 rounded-md transition-colors duration-200 m-1 ${
                      tab.id === activeTab
                        ? "bg-gray-500 text-white" // Active tab styles
                        : "bg-retrobg text-white hover:bg-gray-400" // Inactive tab styles
                    }`}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Content Area */}
          <div className="w-full md:ml-4">
            {activeTab ? (
              // Render content for other tabs
              <div dangerouslySetInnerHTML={{ __html: getContent(activeTab) }} />
            ) : (
              // Default content if no tab is selected
              <div>
                <img src={pixelore} alt="Pixelore" className="h-96 rounded-3xl" />
                <p className="text-lg mt-2">Please select a menu item on the left to view content.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Christmas;
