import React, { useState, useEffect, useCallback } from "react";
import LocationInfo from "../components/Location";
import axios from "axios";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { currencyMapping } from "../utils/mappings";

const PlayerLookup = () => {
  const [search, setSearch] = useState("");
  const [playerData, setPlayerData] = useState(null);
  const [marketListings, setMarketData] = useState(null);
  const [message, setMessage] = useState("");
  const [reputation, setReputation] = useState("0.00");
  const [totals, setTotals] = useState({ totalLevel: 0, totalExp: 0 });
  const [breakdown, setBreakdown] = useState("No data available.");
  const [petCount, setPetCount] = useState(0);
  const [petDetails, setPetDetails] = useState([]);
  const [landDetails, setLandDetails] = useState([]);
  const [filteredMemberships, setFilteredMemberships] = useState([]);
  const [dateInfo, setDateInfo] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { playerId } = useParams();

  const totalQuestsCompleted = playerData?.quests.filter((quest) => quest.isCompleted).length;
  const totalShards = Object.values(filteredMemberships).reduce((sum, count) => sum + count, 0);

  useEffect(() => {
    if (playerId) {
      setSearch(playerId); // Use normalized query for display
      fetchPlayerData(playerId); // Fetch data based on the encoded query
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const calculateAccountAge = (createdAt) => {
    const createdDate = new Date(createdAt);
    const today = new Date();
    const diffTime = today - createdDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    // Format the createdDate into a readable string (e.g., "January 1, 2022")
    const formattedDate = createdDate.toLocaleDateString();

    return { daysDiff: diffDays, startDate: formattedDate };
  };

  const fetchPlayerData = async (query) => {
    try {
      let response;
      const cleanedQuery = query.trim(); // Remove trailing and leading spaces
      const uidRegex = /^[0-9a-fA-F]{24}$/;

      if (cleanedQuery.startsWith("0x") || cleanedQuery.startsWith("ronin:")) {
        // Fetch based on cryptoWalletAddress
        response = await axios.get("https://api.pixelore.wiki/api/playerlookup", {
          params: {
            cryptoWalletAddress: cleanedQuery,
            detail: 5,
            quests: true,
          },
        });
      } else if (uidRegex.test(cleanedQuery)) {
        // If the query matches the UID format, use it as `mid`
        response = await axios.get("https://api.pixelore.wiki/api/playerlookup", {
          params: {
            mid: cleanedQuery,
            detail: 5,
            quests: true,
          },
        });
      } else {
        // Otherwise, use it as `username`
        response = await axios.get("https://api.pixelore.wiki/api/playerlookup", {
          params: {
            username: cleanedQuery,
            detail: 5,
            quests: true,
          },
        });
      }

      if (response.data) {
        setPlayerData(response.data);
        setMessage("");
      } else {
        setPlayerData(null);
        setMessage("No player was found with that username.");
      }
    } catch (error) {
      setPlayerData(null);
      setMessage("Make sure you've got the right name there...");
      console.error("Error fetching player data:", error);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();

    if (!search) {
      setMessage("Please enter a search term.");
      return;
    }

    // Update the URL with the search query
    navigate(`/lookup/players/${search}`);

    fetchPlayerData(search);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); // Format as "MM/DD/YYYY"
  };

  const calculateReputation = useCallback((playerData) => {
    const memberships = playerData.memberships || {};

    let discordRep = memberships["Discord"] ? 100 : 0;
    let phoneRep = memberships["phone"] ? 50 : 0;
    let emailRep = memberships["email"] ? 5 : 0;
    let twitterRep = memberships["Twitter"] ? 50 : 0;
    let vipRep = memberships["vip"] ? 1500 : 0;
    let loRep = memberships["nftLand-pixels"] ? 1500 * (memberships["nftLand-pixels"]?.count < 4 ? memberships["nftLand-pixels"]?.count : 4) : 0;
    let guildRep = 0;
    for (const [key, value] of Object.entries(memberships)) {
      if (key.startsWith("guild-member") && value.role === "Owner") {
        guildRep = 300; // Assign 300 points if any role is 'Owner'
        break; // No need to continue once we find an 'Owner'
      }
    }

    // Initial reputation and breakdown
    let reputation = discordRep + phoneRep + emailRep + twitterRep + vipRep + loRep + guildRep;
    let breakdown = [
      memberships["Discord"] ? `Discord: ${discordRep} points` : null,
      memberships["phone"] ? `Phone: ${phoneRep} points` : null,
      memberships["email"] ? `Email: ${emailRep} points` : null,
      memberships["Twitter"] ? `Twitter: ${twitterRep} points` : null,
      memberships["vip"] ? `VIP: ${vipRep} points` : null,
      memberships["nftLand-pixels"] ? `NFT Land: ${loRep} points` : null,
      guildRep > 0 ? `Guild Owner: ${guildRep} points` : null,
    ]
      .filter(Boolean) // Remove null values
      .join("<br/>");

    const { daysDiff } = calculateAccountAge(playerData.createdAt);
    const accountAgeRep = Math.log10(daysDiff * 0.15) * 40;
    reputation += accountAgeRep;

    const questReputations = [
      { id: "qst_wine2Delivery", points: 50, name: "Popberry Wine Quest (N/A)" },
      { id: "qst_flourPowder", points: 50, name: "Flour Powder Quest (N/A)" },
      { id: "qst_Fuy_Gieris_BBQ2_Tastemaker", points: 75, name: "Fuy Gieris BBQ2 (N/A)" },
      { id: "qst_Fuy_Gieris_BBQ3_Submarine_Sizzler", points: 75, name: "Fuy Gieris BBQ3 (N/A)" },
      { id: "qst_Fuy_Gieris_BBQ4_Galactic_Grill", points: 75, name: "Fuy Gieris BBQ4 (N/A)" },
      { id: "qst_MilkshakeForJihoz", points: 150, name: "Milkshake for Jihoz (N/A)" },
      { id: "qst_wine12Delivery", points: 250, name: "Butterbrew Wine Quest (N/A)" },
      { id: "qst_ygg_03", points: 200, name: "Guide To Guilds 3 (N/A)" },
      { id: "qst_pixels_pavillion", points: 400, name: "Perry Page Maze (N/A)" },
      { id: "qst_shorelime_2024", points: 325, name: "Shorelime 2024 (N/A)" },
      { id: "qst_bazzarn_intro_01", points: 50, name: "Barney's Position Decision" },
      { id: "qst_bucks_1st_time", points: 10, name: "Welcome to the Buck's Galore!" },
      { id: "qst_mill_1st_time", points: 25, name: "Off To The Daily Grind." },
      { id: "qst_textilemaking_1st_time", points: 20, name: "The Apparel Heir Apparent." },
      { id: "qst_winemaking_1st_time", points: 25, name: "Winery? More Like Sublimery!" },
      { id: "qst_metalworking_1st_time", points: 20, name: "That's So METAL!" },
      { id: "qst_woodworking_1st_time", points: 15, name: "Shoulda, Wood-a, Coulda!" },
      { id: "qst_stoneshaping_1st_time", points: 20, name: "A Rocky Start." },
      { id: "qst_buy_coin_coupon_01", points: 100, name: "Reputable Banker" },
      { id: "qst_halloween_2024_Denis", points: 25, name: "Deranged Dental Exchange"},
      { id: "qst_teethReputation", points: 50, name: "Teeth Reputation"},
      { id: "qst_halloween_2024_collection", points: 50, name: "Barney's Bat-ch Catch"},
      { id: "qst_xmasstocking_03", points: 50, name: "Stocking Up For Pixmas (Red)"},
      { id: "qst_xmasstocking_02", points: 50, name: "Stocking Up For Pixmas (White)"},
      { id: "qst_xmasstocking_01", points: 50, name: "Stocking Up For Pixmas (Green)"},
      { id: "qst_xmastree_2024", points: 50, name: "Pixmas Tree Perfection"}
    ];

    const questBreakdown = playerData.quests
      ? playerData.quests
          .map((quest) => {
            const questInfo = questReputations.find((q) => q.id === quest.id);
            if (questInfo && quest.isCompleted && questInfo.points > 0) {
              reputation += questInfo.points;
              return `${questInfo.name} - ${questInfo.points} points`;
            } else {
              return null;
            }
          })
          .filter(Boolean) // Remove null values
          .join("<br/>")
      : ""; // Default to an empty string if playerData.quests is undefined

    return {
      reputation: reputation.toFixed(2), // Round to 2 decimal places
      breakdown: `<strong>Reputation Breakdown:</strong><br/>Account Age: ${accountAgeRep.toFixed(2)} points<br/>${breakdown}${questBreakdown ? "<br/>" + questBreakdown : ""}`,
    };
  }, []);

  useEffect(() => {
    if (playerData) {
      const { reputation, breakdown } = calculateReputation(playerData);
      setReputation(reputation);
      setBreakdown(breakdown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  useEffect(() => {
    const getFilteredMemberships = () => {
      if (!playerData || !playerData.memberships) return {};

      const filteredMemberships = {};

      // Get counts for guild memberships
      Object.entries(playerData.memberships).forEach(([membershipId, membershipData]) => {
        if (membershipId.startsWith("guild-")) {
          const name = membershipId.split("-")[2]; // Extract the name part (e.g., "p2g")
          filteredMemberships[name] = membershipData.count || 0;
        }
      });

      return filteredMemberships;
    };

    if (playerData) {
      // Update filtered memberships when playerData changes
      const updatedMemberships = getFilteredMemberships();
      setFilteredMemberships(updatedMemberships);
    }
  }, [playerData, calculateReputation]);

  //fetch Land Data
  useEffect(() => {
    const fetchLandData = async () => {
      if (!playerData?.memberships || playerData.memberships["nftLand-pixels"]?.count === 0) {
        setLandDetails([]);
        return;
      }

      try {
        const response = await axios.get("https://api.pixelore.wiki/api/playerlands", {
          params: { mid: playerData._id },
        });

        const landdata = response.data.nftFarms;
        setLandDetails(landdata);
      } catch (error) {
        console.error("Error fetching market or item data:", error);
        setLandDetails([]);
      }
    };

    if (playerData && playerData.memberships && playerData.memberships["nftLand-pixels"]?.count > 0) {
      fetchLandData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  //fetch Pet Data
  useEffect(() => {
    const fetchPetData = async () => {
      if (!playerData?.cryptoWallets || playerData.cryptoWallets.length === 0) {
        setPetCount(0);
        setPetDetails([]);
        return;
      }

      try {
        // Fetch pet data for all wallet addresses concurrently
        const responses = await Promise.all(
          playerData.cryptoWallets.map((wallet) =>
            axios.get("https://api.pixelore.wiki/api/pets", {
              params: { walletAddress: wallet.address },
            })
          )
        );

        // Collect pet data from all responses
        const allPetData = responses.flatMap((response) => response.data);

        if (allPetData.length > 0) {
          setPetDetails(allPetData);
          setPetCount(allPetData.length);
        } else {
          setPetCount(0);
          setPetDetails([]);
        }
      } catch (error) {
        console.error("Error fetching pet data:", error);
        setPetCount(0);
        setPetDetails([]);
      }
    };

    if (playerData) {
      fetchPetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  //fetch Market Listings
  useEffect(() => {
    const fetchMarketListings = async () => {
      if (!playerData._id) {
        setMarketData(0);
        return;
      }

      try {
        const response = await axios.get("https://api.pixelore.wiki/api/userlistings", {
          params: { mid: playerData._id },
        });

        const marketData = response.data.listings;
        const itemsResponse = await axios.get("https://api.pixelore.wiki/api/pixelitems");

        const itemsMap = itemsResponse.data.reduce((acc, item) => {
          acc[item.id] = { name: item.name, image: item.image };
          return acc;
        }, {});

        // Add item details to marketData
        const enrichedMarketData = marketData.map((listing) => {
          const itemDetails = itemsMap[listing.itemId];
          return {
            ...listing,
            itemName: itemDetails?.name,
            itemImage: itemDetails?.image,
          };
        });

        setMarketData(enrichedMarketData);
      } catch (error) {
        console.error("Error fetching market or item data:", error);
        setMarketData(0);
      }
    };

    if (playerData) {
      fetchMarketListings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  //calculate account age data
  useEffect(() => {
    if (playerData) {
      const { daysDiff, startDate } = calculateAccountAge(playerData.createdAt);

      const vipMembership = playerData.memberships?.vip;
      let vipStatus = "No";

      if (vipMembership) {
        if (vipMembership.expiration > Date.now()) {
          vipStatus = `Expires: ${formatDate(vipMembership.expiration)}`;
        }
      }

      setDateInfo({
        startDate,
        vipStatus,
        accountAge: daysDiff,
      });
    }
  }, [playerData]);

  //calculate totals for EXP
  useEffect(() => {
    const calculateTotals = () => {
      if (playerData && playerData.quests.length > 0) {
        let totalLevel = 0;
        let totalExp = 0;

        Object.entries(playerData.levels).forEach(([key, levelData]) => {
          if (key !== "overall") {
            totalLevel += levelData.level;
            totalExp += levelData.totalExp;
          }
        });

        setTotals({ totalLevel, totalExp });
      }
    };

    calculateTotals(); // Calculate totals when playerData changes
  }, [playerData]);

  //simplifying IDs for maps. I think I should take this out maybe - nvm
  const simplifyId = (id) => {
    if (id.startsWith("nftHouse")) {
      const number = id.replace("nftHouse", "");
      return `House #${number}`;
    } else if (id.startsWith("pixelsNFTFarm-")) {
      const number = id.replace("pixelsNFTFarm-", "");
      return `Land #${number}`;
    } else {
      return id; // Return the original id if it doesn't match any patterns
    }
  };

  return (
    <div className="container mx-auto mt-2 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-5xl font-heading mb-3 text-center text-retroegg">Player Lookup</h1>
      <form onSubmit={handleSearchSubmit} className="flex justify-center mb-3">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)} // Update search state
          placeholder="Enter Wallet, User id or Username"
          className="p-2 border border-gray-300 rounded text-retrogray w-3/3 md:w-1/3"
        />
        <button type="submit" className="ml-4 p-2 bg-retropink text-white rounded">
          Search
        </button>
      </form>

      {message && <p className="text-red-500 text-center">{message}</p>}
      <hr />

      {playerData && (
        <div className="mt-3 w-[100%] lg:w-5/6 mx-auto bg-retropb rounded-xl p-4 border-retrobabypink border-4 shadow-lg">
          <h2 className="text-3xl text-center mb-1">Player Information</h2>
          <div className="flex flex-wrap gap-3">
            {/* Player Information */}
            <div className="flex-1 w-full md:w-1/2  bg-retrograypurple shadow-sm p-1 shadow-retrobabypink shadow-inner rounded-xl mt-1">
              <div className="mb-6">
                <div className="flex-auto md:flex items-center text-center md:text-left mt-2 text-sm">
                  <div className="relative flex flex-col items-center">
                    <img src={playerData.currentAvatar?.pieces?.image || "/images/wizard.gif"} alt="Avatar" className="w-32 h-32 rounded-full border-2 border-retrowhite" />
                    {/* Guild Emblem */}
                    {playerData.guildHandle && (
                      <div className="absolute bottom-0 right-0">
                        <div className="relative group flex items-center">
                          <Link to={`/lookup/guilds/${playerData.guildHandle}`} target="_blank" rel="noreferrer">
                            <img src={playerData.guildEmblem} alt="Guild Emblem" className="w-10 h-10 rounded-full cursor-pointer hover:scale-[2]" />
                          </Link>
                          {/* Tooltip on hover */}
                          <div className="absolute top-full left-0 mt-2 hidden group-hover:block bg-retrogray border border-gray-300 p-2 shadow-lg rounded w-24">
                            <p>
                              <strong>Pledge:</strong> {playerData.guildHandle || "None"}
                            </p>
                            <p>
                              <strong>Role:</strong> {playerData.guildRole || "None"}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="ml-4 ">
                    <p>
                      <strong>Username:</strong> {playerData.username}
                    </p>
                    <p>
                      <strong>UID:</strong> {playerData._id}
                    </p>
                    <LocationInfo playerData={playerData} />
                    <hr />
                    <p className="mt-2">
                      <strong>Start Date:</strong> {dateInfo.startDate || "N/A"}
                    </p>
                    <p>
                      <strong>VIP:</strong> {dateInfo.vipStatus || "N/A"}
                    </p>
                    <p>
                      <strong>Account Age:</strong> {dateInfo.accountAge ? `${dateInfo.accountAge} days` : "N/A"}
                    </p>
                    <p>
                      <strong>Reputation:</strong> {reputation || "N/A"} <i>(This is extremely innacurate)</i>
                      {/* Show breakdown on hover */}
                      <span className="relative group">
                        <span className="absolute hidden border border-white group-hover:block bg-retrogray text-white text-sm rounded-lg p-3  w-72 md:w-96 z-[101]">
                          <span dangerouslySetInnerHTML={{ __html: breakdown || "" }} />
                        </span>
                        <span className="text-retropink cursor-pointer ml-1">(?)</span>
                      </span>
                    </p>
                    <p>
                      <strong>Total Quests:</strong> {totalQuestsCompleted || "N/A"}
                    </p>
                    <p>
                      <strong>Avatar:</strong>{" "}
                      {playerData.currentAvatar && playerData.currentAvatar.pieces.avatarId
                        ? playerData.currentAvatar.pieces.avatarId + " #" + playerData.currentAvatar.pieces.tokenId
                        : "Using Pixels Avatar"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 flex-col">
              <div className="flex flex-row flex-wrap gap-1">
                {/* Currencies */}
                <div className="flex-1 max-w-1/2 sm:max-w-1/3 md:max-w-2/6 lg:max-w-2/6 mt-1 bg-retrograypurple shadow-sm p-1 shadow-retrobabypink shadow-inner rounded-xl text-center min-h-36 max-h-40 p-2 overflow-auto">
                  <div className="mb-6">
                    <h3 className="text-2xl mb-2">Currencies</h3>
                    <ul>
                      {playerData.coinInventory && playerData.coinInventory.length > 0 ? (
                        playerData.coinInventory
                          .filter((currency) => currency.balance > 0)
                          .map((currency) => (
                            <li className="text-sm flex justify-start" key={currency.currencyId}>
                              <img src={`/images/currencies/${currency.currencyId}.png`} alt={currency.currencyId} className="w-4 h-4 mr-1" />
                              <span className="w-1/2 text-left">{currencyMapping[currency.currencyId] || currency.currencyId}</span>
                              <br />
                              <span className="w-1/2 text-right">{currency.balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </li>
                          ))
                      ) : (
                        <p className="text-xs">No Currencies to Show</p>
                      )}
                    </ul>
                  </div>
                </div>

                {/* Assets */}
                <div className="flex-1 max-w-3/6 sm:max-w-2/3 md:max-w-3/4 lg:max-w-2/6 p-1 mt-1 rounded-xl bg-retrograypurple shadow-sm shadow-retrobabypink shadow-inner text-center text-wrap max-h-40">
                  <div className="mb-6">
                    <h3 className="text-2xl mt-2 mb-2">Assets</h3>
                    <div className="text-sm">
                      {/* Lands */}
                      <p>
                        <i className="fa-solid fa-house" style={{ color: "#9b7cab" }} /> NFT Lands:{" "}
                        {playerData.memberships && playerData.memberships["nftLand-pixels"]?.count ? (
                          <span className="relative cursor-pointer group">
                            {playerData.memberships["nftLand-pixels"].count}

                            {/* Hover effect to show land details */}
                            <div className="land-details-container absolute top-fit mb-2 hidden group-hover:block bg-retrogray text-white text-sm rounded-lg p-2 border border-white z-10">
                              {landDetails.map((nft) => (
                                <div key={nft._id}>
                                  <div className="land-name text-white text-sm">{simplifyId(nft.id)}</div>
                                </div>
                              ))}
                            </div>
                          </span>
                        ) : (
                          <span>0</span>
                        )}
                      </p>

                      {/* Pets */}
                      <div className="relative group">
                        <div>
                          <i className="fa-solid fa-dog" style={{ color: "#3e7e57" }} /> Pets:{" "}
                          <span className="relative">
                            {petCount}
                            {petCount > 0 && (
                              <div className="pet-details-container absolute top-1 mt-2 hidden group-hover:flex text-white text-sm rounded-lg p-2 max-h-64 max-w-96 overflow-x-scroll z-20">
                                {petDetails &&
                                  petDetails.map((pet) => (
                                    <div
                                      className="relative ml-2 pet-info bg-retrogray text-white text-sm rounded-lg p-2 border border-white z-10 w-32  md:min-w-32 mb-2"
                                      key={pet.tokenId}>
                                      <div className="pet-name text-xl font-bold">{pet.avatar.name}</div>
                                      {pet.stage === "mature" ? (
                                        <>
                                          <p>
                                            <a href={`https://marketplace.skymavis.com/collections/pixels-pet/${pet.tokenId}`} rel="noreferrer" target="_blank">
                                              <strong>Token:</strong> {pet.tokenId || ""}
                                            </a>
                                          </p>
                                          <p>
                                            <strong>Body:</strong> {pet.avatar?.pieces?.body || ""}
                                          </p>
                                          <p>
                                            <strong>Luck:</strong> {pet.traits?.Luck?.toFixed(0) || "0"}
                                          </p>
                                          <p>
                                            <strong>Speed:</strong> {pet.traits?.Speed?.toFixed(0) || "0"}
                                          </p>
                                          <p>
                                            <strong>Strength:</strong> {pet.traits?.Strength?.toFixed(0) || "0"}
                                          </p>
                                          <p>
                                            <strong>Food Streak:</strong> {pet.streaks?.food?.current || "0"}
                                          </p>
                                          <p>
                                            <strong>Water Streak:</strong> {pet.streaks?.water?.current || "0"}
                                          </p>
                                        </>
                                      ) : (
                                        <p>
                                          <strong>Stage:</strong> Capsule
                                        </p>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </span>
                        </div>
                      </div>

                      {/* Guild Shards */}
                      <div className="h-24">
                        <div className="flex justify-center items-center relative">
                          <div className="flex items-center">
                            <div>
                              <i className="fa-solid fa-burst" style={{ color: "#b1b851" }} /> Guild Shards:{" "}
                              <span className="relative group">
                                {totalShards}
                                {/* Hover effect to show shard details */}
                                {filteredMemberships && (
                                  <div className="absolute top-fit mb-2 hidden group-hover:block bg-retrogray text-white text-sm rounded-lg p-2 border border-white z-10 md:w-32">
                                    {Object.entries(filteredMemberships).map(([name, count], index) => (
                                      <div key={index}>
                                        <strong>{name}:</strong> {count}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Wallets */}
              <div className="flex-auto max-w-1/4 p-1 bg-retrograypurple shadow-sm shadow-retrobabypink shadow-inner rounded-xl mt-1 text-center max-h-72 text-wrap text-sm">
                <h3 className="text-2xl mb-2">Wallets</h3>
                {playerData.cryptoWallets && playerData.cryptoWallets.length > 0 ? (
                  playerData.cryptoWallets.map((wallet) => (
                    <p key={wallet.address}>
                      [
                      {wallet.providers && wallet.providers.length > 0
                        ? wallet.providers.map((provider) => provider.charAt(0).toUpperCase() + provider.slice(1)).join(", ")
                        : "Provider N/A"}
                      ] {wallet.address}
                    </p>
                  ))
                ) : (
                  <p>None</p>
                )}
              </div>
            </div>
          </div>

          <div className="mt-2 flex flex-col md:flex-row gap-3">
            {/* Levels */}
            <div className="flex-1 md:w-full text-sm md:max-w-1/2 p-4 bg-retrograypurple shadow-sm shadow-retrobabypink shadow-inner rounded-xl">
              <h2 className="text-2xl mb-3">Levels</h2>
              <div className="flex flex-col">
                {/* Display the Overall skill first */}
                {playerData.levels && playerData.levels["overall"] && (
                  <div className="mb-4">
                    <p className="font-bold">
                      <span className="block">
                        <strong>Overall Level:</strong> {playerData.levels["overall"].level}
                      </span>
                      <span className="block">
                        <strong>Overall Exp:</strong> {playerData.levels["overall"].totalExp.toLocaleString()}
                      </span>
                      <span className="block">
                        <strong>Total Level:</strong> {totals.totalLevel}
                      </span>
                      <span className="block">
                        <strong>Total Exp:</strong> {totals.totalExp.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </span>
                    </p>
                  </div>
                )}

                {/* Headings */}
                <div className="flex mb-2">
                  <span className="w-32 font-bold text-left ml-4">Skill</span>
                  <span className="w-12 font-bold text-left ml-2">Level</span>
                  <span className="w-20 font-bold text-left md:block hidden">Current Exp</span>
                  <span className="w-24 font-bold text-left">Total Exp</span>
                </div>

                {/* Display all other skills */}
                {playerData.levels &&
                  Object.entries(playerData.levels)
                    .filter(([key]) => key !== "overall")
                    .map(([key, levelData]) => (
                      <div key={key} className="flex items-center mb-2">
                        <img src={`/images/skills/${key}.png`} alt={key} className="w-4 h-4 mr-2" />
                        <span className="w-32 text-left">
                          <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>
                        </span>
                        <span className="w-12">Lvl {levelData.level}</span>
                        <span className="w-20 text-retropink hidden md:block">
                          {levelData.exp.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                        <span className="w-24 text-retropink">{levelData.totalExp.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                      </div>
                    ))}
              </div>
            </div>

            {/* Market */}
            <div className="flex-1 text-sm md:max-w-1/2 p-2 bg-retrograypurple shadow-md shadow-retrobabypink shadow-inner rounded-xl">
              <h2 className="text-2xl mb-2">Player's Listings</h2>
              <ul className="ml-2">
                {marketListings && marketListings.length > 0 ? (
                  marketListings.map((listing) => (
                    <li key={listing._id} className="flex items-center mb-2">
                      <img src={listing.itemImage} alt={listing.itemName} className="w-6 h-6 mr-2" />
                      <span>
                        {listing.quantity} x {listing.itemName || listing.itemId} for {listing.price.toLocaleString()} Coins each
                      </span>
                    </li>
                  ))
                ) : (
                  <p>{playerData.username} doesn't have anything listed.</p>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerLookup;
